<template>
  <div class="content-wrapper">
    <div class="page-header">
      <h1>{{ $t('SNS Management') }} > {{ $t('Facebook Page') }}</h1>
    </div>

    <el-dialog
    title="Publish Facebook Post"
    :visible.sync="dialogVisible"
    width="30%">
    <el-input
      type="textarea"
      :rows="10"
      placeholder="Input your content"
      v-model="newPostContent">
    </el-input>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="publishContent">Publish</el-button>
    </span>
  </el-dialog>

    <div class="sns">
      <h3>{{ $t('Your Facebook Page Post') }}</h3>
      <div class="view-toolbars">
        <div class="tool">
          <button class="btn btn-primary" @click="dialogVisible = true">
            <i class="el-icon-plus"></i>
            {{ $t('Publish Facebook Post') }}
          </button>
        </div>
      </div>
      <div class="facebook-page">
        <div class="card">
          <div class="card-header">
            <img class="img-fluid page-photo" :src="pagePhoto"/>
            <a target="_blank" :href="pageUrl">{{ pageName }}</a>
          </div>
          <div class="card-body">
            <div class="facebook-page-posts">
              <div class="post" v-for="post in pagePosts">
                <div class="post-time">{{ $t('Post Date') }}: {{ formatDate(post.created_time) }}</div>
                <div class="post-message">{{ post.message }}</div>
                <div class="post-image" v-if="post.full_picture">
                  <img class="img-fluid" :src="post.full_picture"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';
import Sns from '@/lib/sns';

export default {
  name: 'SNS',
  mounted(){
    this.loadFacebookPagePosts();
  },
  data(){
    return {
      'newPostContent': '',
      'dialogVisible': false,
      'pagePhoto': '',
      'pageName': '',
      'pagePosts': [],
      'pageUrl': '',
    };
  },
  methods:{
    async publishContent(){
      const loginInfo = Common.getLoginInfo();
      try{
        const postContent = this.newPostContent;
        const postResult = await Sns.createFacebookPost(this.apiUrl, postContent, loginInfo);
        location.reload();
      }catch(err){
        console.log(err);
      }
    },
    async loadFacebookPagePosts(){
      const loginInfo = Common.getLoginInfo();
      try{
        const facebookPage = await Sns.loadFacebookPage(this.apiUrl, loginInfo);
        const facebookPageId = facebookPage['facebook_page_id']
        const facebookPagePhoto = facebookPage['facebook_page_image'];
        const facebookPageName = facebookPage['facebook_page_name'];
        const facebookPagePosts = facebookPage['facebook_page_posts'];

        const facebookPageNameText = facebookPageName.replace(' ', '-');
        const facebookPageUrl = `https://www.facebook.com/${facebookPageNameText}-${facebookPageId}`;

        this.pagePhoto = facebookPagePhoto;
        this.pageName = facebookPageName;
        this.pagePosts = facebookPagePosts;
        this.pageUrl = facebookPageUrl;
      }catch(err){
        console.log(err);
      }
    },
    formatDate(dateString){
      const date = dayjs(dateString).format('YYYY-MM-DD HH:mm:ss');
      return date;
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.sns{
  margin-top: 50px;
  padding-right: 50px;

  .view-toolbars{
    display: flex;
    justify-content: flex-end;
  }
}
.facebook-page{
  margin-top: 20px;

  .page-photo{
    margin-right: 20px;
  }
}

.facebook-page-posts{
  .post{
    border-bottom: 1px solid #ddd;
    padding-top: 10px;
    padding-bottom: 30px;
    margin-bottom: 10px;
  }
  .post-image{
    margin-bottom: 20px;
  }
  .post-time{
    margin-bottom: 20px;
    font-style:italic;
  }
  .post-message{
    margin-bottom: 10px;
  }
}
</style>
